import React, { lazy } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import "./assets/scss/theme.scss";
import "toastr/build/toastr.min.css";
import { Loader } from "./components/Common/Loader";
import { userRoutes, authRoutes } from "./routes/allRoutes";

const Authmiddleware = lazy(() => import("./routes/middleware/Authmiddleware"));
// layouts Format
const VerticalLayout = lazy(() => import("./components/VerticalLayout/"));
const NonAuthLayout = lazy(() => import("./components/NonAuthLayout"));

if (
  window.location.hostname !== "localhost" &&
  window.location.protocol === "http:"
) {
  window.location = "https://" + window.location.hostname;
}

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    return layoutCls;
  }

  const Layout = getLayout();

  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      <Router>
        <React.Suspense fallback={Loader}>
          <Switch>
            {authRoutes.map((route, idx) => (
              <NonAuthmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
              />
            ))}
          </Switch>
        </React.Suspense>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
