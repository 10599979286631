import {
  GET_HOUSEHOLDS_BEGIN,
  GET_HOUSEHOLDS_SUCCESS,
  GET_HOUSEHOLDS_FAILURE,
  GET_HOUSEHOLDS_TAB,
  USER_HOUSEHOLDS_BEGIN,
  USER_HOUSEHOLDS_SUCCESS,
  USER_HOUSEHOLDS_FAILURE,
  USER_HOUSEHOLDS_UPDATE,
  USER_HOUSEHOLD_INCOME,
  USER_HOUSEHOLD_EXPENCES,
  USER_HOUSEHOLD_OTHER,
  GET_HOUSEHOLDS_TABLE_SEARCH,
  GET_PROFILE_HOUSEHOLD_TABLE_SEARCH,
  GET_DELETE_HOUSEHOLDS_TABLE_SEARCH,
  GET_CASE_TABLE_SEARCH,
  GET_DELETE_CASE_TABLE_SEARCH,
  GET_USER_TABLE_SEARCH,
  GET_GROUP_TABLE_SEARCH,
} from "./actionTypes";

const INIT_STATE = {
  data: [],
  loading: false,
  error: null,
  user: [],
  tab: "1",
  update: false,
  user_income: [],
  user_expences: [],
  user_other: [],
  householdTableSearch: null,
  phouseholdTableSearch: null,
  dhouseholdTableSearch: null,
  caseTableSearch: null,
  dcaseTableSearch: null,
  userTableSearch: null,
  groupTableSearch: null,
};

const Households = (state = INIT_STATE, action) => {
  // console.log('action', action)
  switch (action.type) {
    case GET_HOUSEHOLDS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_HOUSEHOLDS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case GET_HOUSEHOLDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: [],
      };
    // user
    case USER_HOUSEHOLDS_BEGIN:
      return {
        ...state,
        loading: true,
        update: false,
      };
    case USER_HOUSEHOLDS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        update: false,
      };
    case USER_HOUSEHOLDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        user: [],
        update: false,
      };
    case USER_HOUSEHOLDS_UPDATE:
      return {
        ...state,
        loading: false,
        update: action.payload.update,
      };
    case USER_HOUSEHOLD_INCOME:
      return {
        ...state,
        loading: false,
        user_income: action.payload.products,
      };
    case USER_HOUSEHOLD_EXPENCES:
      return {
        ...state,
        loading: false,
        user_expences: action.payload.products,
      };
    case USER_HOUSEHOLD_OTHER:
      return {
        ...state,
        loading: false,
        user_other: action.payload.products,
      };
    case GET_HOUSEHOLDS_TAB:
      return {
        ...state,
        loading: false,
        tab: action.payload.tab,
      };
    case GET_HOUSEHOLDS_TABLE_SEARCH:
      return {
        ...state,
        householdTableSearch: action.payload.search,
      };
    case GET_PROFILE_HOUSEHOLD_TABLE_SEARCH:
      return {
        ...state,
        phouseholdTableSearch: action.payload.search,
      };
    case GET_DELETE_HOUSEHOLDS_TABLE_SEARCH:
      return {
        ...state,
        dhouseholdTableSearch: action.payload.search,
      };
    case GET_CASE_TABLE_SEARCH:
      return {
        ...state,
        caseTableSearch: action.payload.search,
      };
    case GET_DELETE_CASE_TABLE_SEARCH:
      return {
        ...state,
        dcaseTableSearch: action.payload.search,
      };
    case GET_USER_TABLE_SEARCH:
      return {
        ...state,
        userTableSearch: action.payload.search,
      };
    case GET_GROUP_TABLE_SEARCH:
      return {
        ...state,
        groupTableSearch: action.payload.search,
      };
    default:
      return state;
  }
};

export default Households;
