import { GET_USER_PROFILE, GET_USER_UPDATE } from "./actionTypes";

const INIT_STATE = {
  userUpdate: false,
  user: [],
};

const User = (state = INIT_STATE, action) => {
  // console.log('action.type', action.type)
  switch (action.type) {
    case GET_USER_UPDATE:
      return {
        ...state,
        userUpdate: action.payload.userUpdate,
      };
    case GET_USER_PROFILE:
      return {
        userUpdate: true,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export default User;
