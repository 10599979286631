import {
  NEW_REQUEST_PAGE,
  NEW_REQUEST_QUESTION1,
  NEW_REQUEST_QUESTION2,
  NEW_REQUEST_QUESTION3,
  GET_TAB,
} from "./actionTypes";

const INIT_STATE = {
  question1: null,
  question2: false,
  question3: null,
  newRequestPage: 1,
  tab: "1",
};

const Requests = (state = INIT_STATE, action) => {
  // console.log('action.type', action)
  switch (action.type) {
    case NEW_REQUEST_PAGE:
      return {
        ...state,
        newRequestPage: action.payload.page,
      };
    case NEW_REQUEST_QUESTION1:
      return {
        ...state,
        question1: action.payload.question,
      };
    case NEW_REQUEST_QUESTION2:
      return {
        ...state,
        question2: action.payload.question,
      };
    case NEW_REQUEST_QUESTION3:
      return {
        ...state,
        question3: action.payload.question,
      };
    case GET_TAB:
      return {
        ...state,
        tab: action.payload.tab,
      };
    default:
      return state;
  }
};

export default Requests;
