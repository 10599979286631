/* get all */
export const GET_HOUSEHOLDS_BEGIN = "GET_HOUSEHOLDS_BEGIN";
export const GET_HOUSEHOLDS_SUCCESS = "GET_HOUSEHOLDS_SUCCESS";
export const GET_HOUSEHOLDS_FAILURE = "GET_HOUSEHOLDS_FAILURE";

/* get user */
export const USER_HOUSEHOLDS_BEGIN = "USER_HOUSEHOLDS_BEGIN";
export const USER_HOUSEHOLDS_SUCCESS = "USER_HOUSEHOLDS_SUCCESS";
export const USER_HOUSEHOLDS_FAILURE = "USER_HOUSEHOLDS_FAILURE";
export const USER_HOUSEHOLDS_UPDATE = "USER_HOUSEHOLDS_UPDATE";

/* get tabs  */
export const GET_HOUSEHOLDS_TAB = "GET_HOUSEHOLDS_TAB";

/* get user household financial table  */
export const USER_HOUSEHOLD_INCOME = "USER_HOUSEHOLD_INCOME";
export const USER_HOUSEHOLD_EXPENCES = "USER_HOUSEHOLD_EXPENCES";
export const USER_HOUSEHOLD_OTHER = "USER_HOUSEHOLD_OTHER";

/* get table search  */
export const GET_HOUSEHOLDS_TABLE_SEARCH = "GET_HOUSEHOLDS_TABLE_SEARCH";
export const GET_PROFILE_HOUSEHOLD_TABLE_SEARCH =
  "GET_PROFILE_HOUSEHOLD_TABLE_SEARCH";
export const GET_DELETE_HOUSEHOLDS_TABLE_SEARCH =
  "GET_DELETE_HOUSEHOLDS_TABLE_SEARCH";
export const GET_CASE_TABLE_SEARCH = "GET_CASE_TABLE_SEARCH";
export const GET_DELETE_CASE_TABLE_SEARCH = "GET_DELETE_CASE_TABLE_SEARCH";
export const GET_USER_TABLE_SEARCH = "GET_USER_TABLE_SEARCH";
export const GET_GROUP_TABLE_SEARCH = "GET_GROUP_TABLE_SEARCH";
