import { combineReducers } from "redux";
// dashboard layout
import Layout from "./layout/reducer";
// households
import household from "./household/reducer";
// dashboard
import dashboard from "./dashboard/reducer";

// frontend
import requests from "./frontend/requests/reducer";

const rootReducer = combineReducers({
  // admin
  Layout,
  household,
  dashboard,

  // frontend
  requests,
});

export default rootReducer;
